
import { defineComponent, PropType, reactive, ref, toRefs } from 'vue';
import DiscussTagGroup from '@/modules/miaokuan-manage/common/components/discuss-tag-group/index.vue';
import { SUB_STATUS_ENUMS } from '@/modules/miaokuan-manage/common/constant';
import { IGetDomesticGoodsInfoRes } from '@/modules/resource-manage/domestic-goods/api/type.d';
import {
  ONLINE_TYPE,
  COMMODITY_STATUS_ENUM,
  SALESTATUS,
} from '@/modules/miaokuan-manage/common/components/shopDetail/constant';
import { useMathFormat } from '@/modules/miaokuan-manage/common/composables/use-math-format';
import { getCommodityLog } from '@/modules/information-manage/shop-manage/api';
import dayjs from 'dayjs';
import { datefuns } from 'cx-utils';

export default defineComponent({
  emits: ['refresh'],
  components: { DiscussTagGroup },
  props: {
    data: {
      type: Object as PropType<IGetDomesticGoodsInfoRes>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { intValidator } = useMathFormat();
    const baseLabels = [
      {
        label: '渠道名称',
        attr: 'channelName',
      },
      {
        label: '品牌',
        attr: 'brandName',
      },
      {
        label: 'SPU',
        attr: 'spu',
      },
      {
        label: '商品状态',
        attr: 'spuStatus',
      },
      {
        label: '是否新品',
        attr: 'isNew',
      },
    ];
    const saleLabels = [
      {
        label: '价格',
        attr: 'price',
      },
      {
        label: '日销峰值',
        attr: 'trdSalesSpuNumPeak1d',
      },
      {
        label: '近7天销量',
        attr: 'salesNumNear7',
      },
      {
        label: '近30天销量',
        attr: 'salesNumNear30',
      },
      {
        label: '总销量',
        attr: 'salesNum',
      },
      {
        label: '总收藏数',
        attr: 'collectNum',
      },
      {
        label: '总评论数',
        attr: 'commentNum',
      },
      {
        label: '上架日期',
        attr: 'latelyShelfDate',
      },
      {
        label: '下架日期',
        attr: 'latelyOffDate',
      },
    ];

    const info = reactive({
      base: ref<Array<{ label: string; attr: string; value: string; }>>([]),
      sale: ref<Array<{ label: string; attr: string; value: string; }>>([]),
      description: ref<string>(props.data.description),
      score: ref<string>(props.data.description),
      tags: ref<string[]>([]),
      etlTime: ref<string>(props.data.etlTime),
      similarity: ref<string>(props.data.descriptionSimilarity),
      shelfLogList: ref<string[]>([]),
    });
    baseLabels.forEach((item) => {
      let value = (props.data as any)[item.attr];
      if (item.label === '商品状态') {
        const name = `STATUS${value}` as ('STATUS1' | 'STATUS2' | 'STATUS3' | 'STATUS4');
        value = SALESTATUS[name];
      }
      if (item.label === '是否新品') {
        value = value === '1' ? '是' : '否';
      }
      info.base.push({ ...item, value });
    });
    saleLabels.forEach((item) => {
      let value = (props.data as any)[item.attr];
      if (item.label === '上架日期' || item.label === '下架日期') {
        value = value ? dayjs(value).format('YYYY年MM月DD日') : '';
      }
      info.sale.push({ ...item, value });
    });
    info.tags = props.data.commentTag ? JSON.parse(props.data.commentTag) : [];
    const getShelfLogList = async () => {
      const { spu, storeId, channelId } = props.data;
      const { data } = await getCommodityLog({
        spu,
        storeId,
        channelId: channelId as string,
      });
      let firstOnTheShelf = false;
      info.shelfLogList = data.map((log, index) => {
        const { itmShelfSpuDate, spuStatus } = log;
        let status: string = '';
        if (spuStatus === COMMODITY_STATUS_ENUM.ON_THE_SHELF) {
          status = '上架';
          if (!firstOnTheShelf) {
            firstOnTheShelf = true;
            status = '首次上架';
          }
        } else if (spuStatus === COMMODITY_STATUS_ENUM.REMOVED) {
          status = '下架';
        }
        return `${index + 1}、${status} ${datefuns.formatTime(itmShelfSpuDate, 'YYYY-MM-DD')}`;
      });
    };
    if (props.data.onlineType === ONLINE_TYPE.SLOW) {
      /* 上下架先不做 */
      // getShelfLogList();
    }

    return {
      ...toRefs(info),
      intValidator,
      SUB_STATUS_ENUMS,
      dayjs,
    };
  },
});
